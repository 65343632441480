import React, { useEffect, useState } from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { Link } from "react-router-dom";
// import pic1 from "../../../assets/landing-page/logo.png";
import { Button } from "@mui/joy";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
// import { RiCloseLargeLine } from "react-icons/ri";
import { MdContentCopy } from "react-icons/md";
import { formatTimeAgo } from "../../utils/formatTimeAgo";
import { FaXTwitter } from "react-icons/fa6";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";

import {
  WhatsApp,
  Twitter,
  Facebook,
  Mail,
  ContentCopy,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { createSlug } from "../../utils/createSlug";

// Import react-share components

const BlogCard = ({
  blogId,
  title_url,
  author,
  title,
  description,
  image,
  createdAt,
  onShare,
}) => {
  // const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  // const handleShareClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setOpen(false); // Correctly update the state to close the Dialog
  };

  const handleClickOpen = () => {
    setOpen(true); // Open the Dialog
  };

  const id = open ? "share-popover" : undefined;

  const truncateDescription = (text, wordLimit) => {
    const words = text?.split(" ");
    if (words?.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const slug = createSlug(title);
  const shareUrl = `${window.location.origin}/blog/${title_url}`;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl);
    toast.success("Link copied to clipboard!");
  };

  const [timeAgo, setTimeAgo] = useState(formatTimeAgo(createdAt));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeAgo(formatTimeAgo(createdAt));
    }, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [createdAt]);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          minWidth: 300,
          "--Card-radius": (theme) => theme.vars.radius.xs,
          borderRadius: "8px",
          backgroundColor: "#dcdaff",
        }}
      >
        <CardOverflow>
          <AspectRatio>
            <Link to={`/blog/${title_url}`}>
              <img src={image} alt="" loading="lazy" />
            </Link>
          </AspectRatio>
        </CardOverflow>
        <CardContent
          orientation="horizontal"
          sx={{ alignItems: "center", mx: -1, color: "#4d16a4" }}
        >
          <Box sx={{ width: "100%", display: "flex", gap: 0.5 }}>
            <CardContent
              orientation="horizontal"
              sx={{ alignItems: "center", gap: 1, color: "#4d16a4" }}
            >
              <Box
                sx={{
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    m: "-2px",
                    borderRadius: "50%",
                  },
                }}
              >
                <img
                  // src={pic1}
                  src="/images/Batuk-logo.png"
                  width={80}
                  // style={{
                  //   width: "3rem",
                  //   height: "3rem",
                  //   borderRadius: "5px",
                  // }}
                />
              </Box>
              <Typography sx={{ fontWeight: "lg", color: "#4d16a4" }}>
                {author}
              </Typography>
            </CardContent>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", gap: 0.5, mx: "auto" }}
          ></Box>
          <Box sx={{ width: 0, display: "flex", flexDirection: "row-reverse" }}>
            <IconButton
              variant="plain"
              color="neutral"
              size="sm"
              aria-describedby={id}
              onClick={handleClickOpen}
            >
              <ShareRoundedIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
              <Box
                sx={{
                  width: "95%",
                  display: "flex",
                  gap: 0.5,
                  marginTop: "1rem",
                  marginLeft: "1rem",
                }}
              >
                <CardContent
                  orientation="horizontal"
                  sx={{ alignItems: "center", gap: 1, color: "#4d16a4" }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        m: "-2px",
                        borderRadius: "50%",
                      },
                    }}
                  >
                    <img
                      // src={pic1}
                      src="/images/Batuk-logo.png"
                      width={80}
                      // style={{
                      //   width: "3rem",
                      //   height: "3rem",
                      //   borderRadius: "5px",
                      // }}
                    />
                  </Box>
                  <div style={{ display: "flex", width: "90%" }}>
                    <h6
                      style={{
                        width: "100%",
                        fontWeight: "lg",
                        color: "#4d16a4",
                      }}
                    >
                      {title}
                    </h6>
                    <button
                      onClick={handleClose} // Close Dialog
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                    >
                      {/* <RiCloseLargeLine /> */}
                    </button>
                  </div>
                </CardContent>
              </Box>

              <DialogContent>
                <p style={{ display: "flex", justifyContent: "center" }}>
                  Share this post
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                    margin: "20px 0",
                  }}
                >
                  <IconButton
                    onClick={() =>
                      window.open(
                        `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                          shareUrl
                        )}`,
                        "_blank"
                      )
                    }
                  >
                    {/* <Twitter
                      style={{ color: "black", height: "3rem", width: "3rem" }}
                    /> */}
                    <FaXTwitter size="40" />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      window.open(
                        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                          shareUrl
                        )}`,
                        "_blank"
                      )
                    }
                  >
                    {/* <Facebook
                      sx={{
                        // color: "#4267B2",
                        color: "#3B5998",
                        // color: "red",
                        height: "3rem",
                        width: "3rem",
                      }}
                    /> */}
                    <FaSquareFacebook size="40" color="#3B5998" />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      window.open(
                        `https://api.whatsapp.com/send?text=${encodeURIComponent(
                          shareUrl
                        )}`,
                        "_blank"
                      )
                    }
                  >
                    {/* <WhatsApp
                      style={{
                        color: "#25D366",
                        height: "3rem",
                        width: "3rem",
                      }}
                    /> */}
                    <FaWhatsapp size="40" color="green" />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      window.open(`https://www.instagram.com`, "_blank")
                    }
                  >
                    <FaInstagram size="40" color="red" />
                  </IconButton>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <input
                    type="email"
                    placeholder="Enter email"
                    value={shareUrl}
                    style={{
                      border: "1px solid",
                      fontSize: "13.5px",
                      // width: "24rem",
                      padding: "9px",
                      width: "100%",
                    }}
                  />
                  <div
                    onClick={handleCopyLink}
                    startIcon={<ContentCopy />}
                    style={{
                      width: "4rem",
                      height: "41px",
                      border: "1px solid",
                      borderRadius: "3px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MdContentCopy style={{ fontSize: "1.5rem" }} />
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </Box>
        </CardContent>
        <CardContent>
          <Typography sx={{ fontSize: "sm", color: "#4d16a4" }}>
            <Link
              to={`/blog/${title_url}`}
              style={{
                textDecoration: "none",
                color: "inherit",
                fontWeight: "bold",
              }}
              className="hover-underline"
            >
              {title}
            </Link>
            <br />
            {truncateDescription(description, 15)}
            {/* {description} */}
          </Typography>
          <Typography
            sx={{ fontSize: "10px", color: "text.tertiary", my: 0.5 }}
          >
            {timeAgo}
          </Typography>
          <Link
            to={`/blog/${title_url}`}
            style={{ textDecoration: "none", color: "white" }}
          >
            <Button
              variant="contained"
              sx={{
                width: "100%",
                backgroundColor: "#4a2a7d",
                color: "white",
                "&:hover": {
                  backgroundColor: "#4a2a7d",
                },
              }}
            >
              Read More
            </Button>
          </Link>
        </CardContent>
      </Card>
    </>
  );
};

export default BlogCard;
