import React, { useEffect, useState } from "react";
// import gold from "../../images/Goldd.png";
// import { FaRupeeSign } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSpotGoldProductDetailsApi } from "../../redux/features/spotGold/spotGoldProductDetailsSlice";
import { toast } from "react-toastify";
import {
  razorpayOrderApi,
  resetState,
} from "../../redux/features/razorpay/razorpayOrderSlice";
import { openRazorpay } from "../../helpers/razorpayHelper";
import { Backdrop, CircularProgress } from "@mui/material";
import { load } from "@cashfreepayments/cashfree-js";

const Confirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("LOCATION^^^^^^^^^^++++", location.state);

  const [coinDetail, setCoinDetail] = useState(location.state.coinDetail);
  const [address, setAddress] = useState(location.state.address);

  const dispatch = useDispatch();

  const [orderTransactionId, setOrderTransactionId] = React.useState();

  const sliceResponse = useSelector(
    (state) => state.spotGoldProductDetailsSlice.spotGoldProductDetails.data
  );

  const { t } = useTranslation();
  const { checkout, purchaseDetails, price, deliveryAt, buyBtn } =
    t("confirmationPage");

  useEffect(() => {
    const login = sessionStorage.getItem("isLOGIN");
    if (!login) {
      navigate("/");
    }
  }, []);

  const Token = sessionStorage.getItem("token");

  const handlePayment = async (TotalAmount) => {
    if (!TotalAmount) {
      toast.error("Please try again later...");
      return;
    }
    await getTransactionIdApi(TotalAmount);
  };

  const getTransactionIdApi = async (TotalAmount) => {
    const payload = {
      productId: location?.state?.coinDetail?.id || "",
    };

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/redeem/quote`,
        payload,
        { headers }
      );

      const transaction_Id = response?.data?.data?.merchantTransactionId || "";

      setOrderTransactionId(transaction_Id);

      if (transaction_Id) {
        const payload = {
          TotalAmount,
          transaction_id: transaction_Id,
        };
        dispatch(razorpayOrderApi(payload));
      } else {
        toast.error("Something Went Wrong!!!");
      }
    } catch (error) {
      console.error("Error fetching transaction ID:", error);
    }
  };

  const razorpayOrderSliceResponse = useSelector(
    (state) => state.razorpayOrderSlice.data.data
  );

  // ====================== CASH FREE======================

  const openCashfree = async () => {
    const cashfree = await load({
      mode: process.env.REACT_APP_CASHFREE,
    });

    let checkoutOptions = {
      paymentSessionId: razorpayOrderSliceResponse.payment_session_id,
      redirectTarget: "_modal",
    };

    cashfree.checkout(checkoutOptions).then((result) => {
      if (result.error) {
        toast.error(result.error);
      }
      if (result.redirect) {
        // This will be true when the payment redirection page couldnt be opened in the same window
        // This is an exceptional case only when the page is opened inside an inAppBrowser
        // In this case the customer will be redirected to return url once payment is completed
        console.log("Payment will be redirected");
      }
      if (result.paymentDetails) {
        // This will be called whenever the payment is completed irrespective of transaction status
        console.log("Payment has been completed, Check for Payment Status");
        console.log(result.paymentDetails.paymentMessage);

        oderplaceApi();
        dispatch(resetState());
      }
    });
  };

  const handleOpenRazorpay = async () => {
    const options = {
      key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
      amount: razorpayOrderSliceResponse?.amount,
      currency: razorpayOrderSliceResponse?.currency,
      name: "Bharat Batuk Pvt.ltd.",
      description: "XYZ",
      order_id: razorpayOrderSliceResponse?.id,
      notes: {
        transactionId: orderTransactionId,
      },
      handler: function (response) {
        oderplaceApi();
        dispatch(resetState());
      },
    };

    const rzp = new window.Razorpay(options);

    // Handling payment failure
    rzp.on("payment.failed", function (response) {
      console.log("Payment failed:", response);
      Swal.fire({
        title: "Payment Failed",
        text: response.error.description,
        icon: "error",
      });
    });

    rzp.open();
  };

  const [loading, setLoading] = useState(false); // Start as false

  const oderplaceApi = async () => {
    setLoading(true); // Set loading to true at the start of the function

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Token}`,
    };

    const payload = {
      product1: coinDetail.sku,
      quantity1: "1",
      addressId: address._id,
      merchantTransactionId: orderTransactionId,
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/redeem/postOrder`,
        payload,
        { headers }
      );

      const responseData = res.data?.data?.[0];
      Swal.fire({
        title: "Payment Successful",
        text: `Transaction Number: ${responseData?.result?.data?.merchantTransactionId}`,
        icon: "success",
      });
      navigate("/dashboard/history");
    } catch (err) {
      console.error("Error occurred:", err);
      Swal.fire({
        title: "Payment Failed",
        text: "There was an issue with your transaction. Please try again.",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (razorpayOrderSliceResponse?.id) {
      handleOpenRazorpay();
      // openCashfree();
    }

    return () => {
      dispatch(resetState());
    };
  }, [razorpayOrderSliceResponse]);

  // useEffect(() => {
  //   if (razorpayOrderSliceResponse?.payment_session_id) {
  // handleOpenRazorpay();
  // openCashfree();
  //   }

  //   return () => {
  //     dispatch(resetState());
  //   };
  // }, [razorpayOrderSliceResponse]);

  return (
    <div>
      <div>
        <Backdrop
          sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div className="p-4">
        <div className="row">
          <div className="col-12">
            <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
              {checkout}
            </h3>
            <div className="contentshadow">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 20,
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    // src={
                    //   coinDetail.metalType === "gold"
                    //     ? "/goldcoin.png"
                    //     : coinDetail.metalType === "silver"
                    //     ? "/silvercoin.png"
                    //     : sliceResponse?.productImage
                    //     ? sliceResponse.productImage
                    //     : "/defaultcoin.png"
                    // }

                    src={
                      location?.state?.coinDetail?.productImages[0]?.url ||
                      "/goldcoin.png"
                    }
                    alt="coin"
                    style={{ width: "100px" }}
                  />
                </div>
                <div>
                  <h5>
                    {coinDetail.name ||
                      sliceResponse?.productName ||
                      "Default Name"}
                  </h5>
                  <p>
                    {coinDetail.productWeight ||
                      sliceResponse?.weight ||
                      "Default Weight"}{" "}
                    gm
                  </p>
                  <p>
                    ₹
                    {coinDetail.basePrice ||
                      sliceResponse?.productPrice[0]?.finalProductPrice ||
                      "Default Price"}
                  </p>
                  {/* <h5>{coinDetail.name}</h5> */}
                  {/* <p>{coinDetail.productWeight} gm</p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="contentshadow">
              <h5 className="colored">{purchaseDetails}</h5>
              {/* <div>{coinDetail.description}</div> */}
              <p>
                {coinDetail.description ||
                  sliceResponse?.metaDescription ||
                  "Default Description"}
              </p>
            </div>
          </div>
          {/* <div className="col-12">
            <div className="contentshadow">
              <h5 className="colored">{price}</h5>
              <div> ₹{coinDetail.basePrice}</div>
              <div>
                ₹
                {sliceResponse?.productPrice[0]?.finalProductPrice
                  ? sliceResponse?.productPrice[0]?.finalProductPrice
                  : coinDetail.basePrice}
              </div>
            </div>
          </div> */}
          <div className="col-12">
            <div className="contentshadow">
              <h5 className="colored">{deliveryAt}</h5>
              <div>
                {address.line1} {address.line2}
              </div>
              <div>
                {address.city} {address.state}
              </div>
              <div>{address.mobileNumber}</div>
              <div>{address.zip}</div>
            </div>
          </div>
          <div
            className="col-12"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 20,
            }}
          >
            <button
              className="btn w-75 text-light"
              onClick={() => handlePayment(coinDetail.basePrice)}
              style={{ backgroundColor: "#472878" }}
            >
              {buyBtn}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
