import React from "react";
import Slider from "react-slick";
import Footer from "../../components/footer/Footer";
import "./AboutUs.css";
import Breadcrumb from "../../components/Breadcrumb";

import { GrTechnology } from "react-icons/gr";
import { FaRegHandshake } from "react-icons/fa";

import { IoPersonOutline } from "react-icons/io5";
import { GoPeople } from "react-icons/go";
import { PiLeafBold, PiLeafLight } from "react-icons/pi";
import { MdOutlineMenuBook, MdOutlinePeopleAlt, MdPersonOutline } from "react-icons/md";

const AboutUs = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <div className="about" id="about">
      <div>
        <Breadcrumb currentPageName="About Us" />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "3rem 0",
        }}
      >
        {/* <h2>About Us</h2> */}
        <div className="row align-items-center mb-5">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="control-container">
              <div>
                <img
                  src="/images/aboutUs_main.png"
                  alt="about us"
                  width="100%"
                  // height="300px"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-12 mb-4 mb-lg-0">
            <h3 style={{ color: "#4A2A7D" }}>Who Are We?</h3>
            <p className="about-description" style={{ color: "#4A2A7D" }}>
              Batuk is your trusted platform for purchasing Digital Gold and
              Digital Silver, offering convenience and accessibility like never
              before. Starting with as little as ₹100, our platform allows
              retail buyers to save in Gold, with every purchase securely stored
              in vaults monitored by Independent trustees, ensuring the highest
              level of security and peace of mind.
            </p>
            <p className="about-description" style={{ color: "#4A2A7D" }}>
              In a nation where Gold is a symbol of prosperity, security, and
              celebration, Batuk caters to everyone from middle class families
              to daily wage earners and the affluent. We understand the deep
              cultural significance of Gold in India, and our mission is to make
              it easier for you to embrace this timeless tradition and build
              your wealth with confidence.
            </p>
          </div>
        </div>

        {/* Principles Section */}
        <div className="principles-section">
          <h2 className="about-title" style={{ color: "#4A2A7D" }}>
            Principles That Define Us
          </h2>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="principle-card">
                {/* <i className="icon customer-icon"></i> */}
                {/* <FaArrowsDownToPeople /> */}
                <MdPersonOutline
                  size={30}
                  className="vibrate-on-hover"
                  style={{ color: "#4A2A7D" }}
                />
                <h3 style={{ color: "#4A2A7D" }}>Customer-Focused Approach</h3>
                <p style={{ color: "#4A2A7D" }}>
                  At Batuk, our customers are our top priority. We are dedicated
                  to understanding and meeting their needs to deliver a smooth
                  and satisfying experience.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="principle-card">
                {/* <i className="icon innovation-icon"></i> */}
                <GrTechnology
                  size={30}
                  className="vibrate-on-hover"
                  style={{ color: "#4A2A7D" }}
                />
                <h3 style={{ color: "#4A2A7D" }}>Innovation & Technology</h3>
                <p style={{ color: "#4A2A7D" }}>
                  We are transforming the gold industry by making transactions
                  seamless, secure, and accessible to everyone.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="principle-card">
                {/* <i className="icon transparency-icon"></i> */}
                <FaRegHandshake
                  size={30}
                  className="vibrate-on-hover"
                  style={{ color: "#4A2A7D" }}
                />
                <h3 style={{ color: "#4A2A7D" }}>Transparency & Trust</h3>
                <p style={{ color: "#4A2A7D" }}>
                  We uphold high standards of trust in every interaction,
                  offering clear and honest information to build lasting
                  relationships founded on reliability and accountability.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="principle-card">
                {/* <i className="icon accessibility-icon"></i> */}
                <MdOutlinePeopleAlt
                  size={30}
                  className="vibrate-on-hover"
                  style={{ color: "#4A2A7D" }}
                />
                <h3 style={{ color: "#4A2A7D" }}>
                  Accessibility and Inclusivity
                </h3>
                <p style={{ color: "#4A2A7D" }}>
                  Batuk is committed to making Digital Gold and Silver
                  accessible to everyone with services like Gold on EMI and F.D.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="principle-card">
                {/* <i className="icon sustainability-icon"></i> */}
                <PiLeafBold
                  size={30}
                  className="vibrate-on-hover"
                  style={{ color: "#4A2A7D" }}
                />
                <h3 style={{ color: "#4A2A7D" }}>
                  Sustainability and Responsibility
                </h3>
                <p style={{ color: "#4A2A7D" }}>
                  Batuk is dedicated to sustainable practices, ethical gold
                  sourcing, and supporting community initiatives, all while
                  striving for a positive impact.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="principle-card">
                {/* <i className="icon learning-icon"></i> */}
                <MdOutlineMenuBook
                  size={30}
                  className="vibrate-on-hover"
                  style={{ color: "#4A2A7D" }}
                />
                <h3 style={{ color: "#4A2A7D" }}>Learning and Improvement</h3>
                <p style={{ color: "#4A2A7D" }}>
                  Driving constant growth and innovation, we stay ahead in the
                  gold industry by embracing fresh ideas and continuously
                  evolving.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* MAP Section */}
        <div className="principles-section">
          <div
            className="row"
            style={{
              margin: "2rem 3rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src="/images/map.png" alt="gold map" style={{ width: "55rem" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
