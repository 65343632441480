import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { categoryApi } from "../../redux/features/home/categorySlice";
import { getSpotGoldApi } from "../../redux/features/spotGold/spotGoldSlice";
import "./Shop.css";
import { addProductCartApi } from "../../redux/features/cart/addProductCartSlice";
import { toast } from "react-toastify";
import ProductsSkeleton from "../../components/ProductsSkeleton";
import { loadCart } from "../../redux/features/cart/getSessionCartSlice";
import { getProductCartApi } from "../../redux/features/cart/getProductCartSlice";
import CustomProductCard from "../../components/CustomProductCard";
import { shopBannerApi } from "../../redux/features/shop/shopBannerSlice";
import ShopBanner from "../../components/ShopBanner";

const catagoryImages = [
  { img: "/images/chainCatagory.webp", name: "Chains", alt: "gold Chains" },
  { img: "/images/ringCatagory.webp", name: "Rings", alt: "gold rings" },
  { img: "/images/pendantCatagory.webp", name: "Pendants", alt: "gold pendants" },
  { img: "/images/earringCatagory.webp", name: "Earrings", alt: "gold earrings" },
  { img: "/images/coinCatagory.webp", name: "Gold Coin", alt: "gold coins" },
  { img: "/images/braceletCatagory.webp", name: "Bracelets", alt: "gold braceletes" },
  { img: "/images/bangleCatagory.webp", name: "Bangles", alt: "gold bangles" },
];

export default function Shop() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const categorySliceResponse = useSelector(
    (state) => state.categorySlice.data.data
  );


  const testing = useSelector(
    (state) => state.categorySlice.data.data?.[12]?.products?.[0]?.subCategoryId
  );


  const spotGoldSliceResponse = useSelector(
    (state) => state.spotGoldSlice.spotGold.data
  );

  const spotGoldSliceLoading = useSelector(
    (state) => state.spotGoldSlice.status
  );

  useEffect(() => {
    dispatch(categoryApi());
    dispatch(getSpotGoldApi());
  }, []);

  const handleAddCart = (item) => {
    const product = {
      product_id: item.id,
      name: item.productName,
      img: item.productImage,
      weight: item.weight,
      quantity: 1,
      price: item?.productPrice[0]?.finalProductPrice,
    };

    if (token) {
      dispatch(addProductCartApi(product));
      dispatch(getProductCartApi());
      toast.success("Item added to cart");
    } else {
      let cart = JSON.parse(sessionStorage.getItem("cart")) || [];

      // Check if the product is already in the cart
      const productIndex = cart.findIndex((p) => p.product_id === item.id);

      if (productIndex !== -1) {
        // If the product is already in the cart, increase the quantity
        cart[productIndex].quantity += 1;
      } else {
        // If the product is not in the cart, add it
        cart.push(product);
      }

      sessionStorage.setItem("cart", JSON.stringify(cart));
      dispatch(loadCart());
      toast.success("Item added to cart");
    }
  };

  const shopBannerApiResponse = useSelector(
    (state) => state.shopBannerSlice.data.data
  );

  useEffect(() => {
    dispatch(shopBannerApi());
  }, []);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
        <div>
          {shopBannerApiResponse && (
            <ShopBanner shopBannerApi={shopBannerApiResponse} />
          )}
        </div>
        <div
          style={{ textAlign: "center", padding: "15px", color: "#4a2a7d" }}
          className="universal_container"
        >
          <h1 style={{ marginBottom: "4rem" }}>Explore by Category</h1>
          <div className="category_main">
            {spotGoldSliceLoading == "loading"
              ? [...Array(5)].map((_, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      marginBottom: "16px", // Add some margin if needed between skeletons
                    }}
                  >
                    <Skeleton variant="circular" width={140} height={140} />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1rem", marginTop: "1rem" }}
                      width={140}
                    />
                  </div>
                ))
              : categorySliceResponse?.data
                  ?.filter((item) => item?.products?.length > 0)
                  .map((item, i) => (
                    <div key={i}>
                      <Link
                        // to={`category/${item.categoryId}`}
                        to={`/shop/category/${item?.products?.[0]?.subCategoryId}`}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <div>
                          {catagoryImages
                            .filter(
                              (test) => item?.subCategoryName == test.name
                            )
                            ?.map((category, j) => (
                              <div key={j}>
                                <img
                                  key={j}
                                  src={category?.img || "/images/noImage.png"}
                                  alt={category?.alt}
                                  className="category-img"
                                />
                                <div>
                                  <h4
                                    // style={{
                                    //   marginTop: "10px",
                                    //   fontSize: "0.9rem",
                                    //   color: "#4a2a7d",
                                    // }}
                                  >
                                    {category.name}
                                    {/* {item?.category?.categoryName} */}
                                  </h4>
                                </div>
                              </div>
                            ))}
                        </div>
                      </Link>
                    </div>
                  ))}
          </div>
        </div>
        {/* ==========================Banner 2===================== */}
        <div>
          <div>
            <img
              src="/images/shopBanner2.webp"
              alt="banner2"
              width="100%"
              // height="400"
            />
          </div>
        </div>
        {/* ==========================Gold Collection===================== */}
        <div>
          <div
            className="universal_container"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h2 style={{ color: "#4a2a7d" }}>Gold Collection</h2>
            <Button
              onClick={() => navigate("/shop/allProducts")}
              variant="container"
              size="small"
              sx={{
                backgroundColor: "#472878",
                color: "white",
                "&:hover": {
                  backgroundColor: "#472878",
                },
              }}
            >
              View All
            </Button>
          </div>
        </div>
        <div className="gold-products-container">
          {spotGoldSliceLoading === "loading" ? (
            <>
              {[...Array(5)].map((_, index) => (
                <ProductsSkeleton key={index} />
              ))}
            </>
          ) : (
            spotGoldSliceResponse?.data
              ?.slice(0, 10)
              ?.map((item, index) => (
                <CustomProductCard
                  key={index}
                  item={item}
                  handleAddCart={handleAddCart}
                />
              ))
          )}
        </div>
        {/* ==========================Banner 3===================== */}
        <div >
          <div style={{ maxWidth:"1240px ",margin:"0 auto 3rem" }}>
            <img
              src="/images/shopBanner33.png"
              alt="banner2"
              width="100%"
              // height="400"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
