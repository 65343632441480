import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TbFileInvoice } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import "../Goldpage/goldify.css";
import "../Goldpage/goldify.scss";

function SilverInvoice() {
  const { t } = useTranslation();
  const { rateOfSilver, gst } = t("silverInvoicePage");
  const {
    augmont,
    silverWeight,
    paymentDetails,
    weight,
    totalAmt,
    transactionId,
    uniqueId,
    invoiceNum,
    status,
    success,
    getInvoice,
  } = t("historyInvoicePage");

  const navigate = useNavigate();
  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  const location = useLocation();
  const data = location.state;

  useEffect(() => {
    if (!data || !data.response || !data.buydata) {
    } else {
      // console.log("Invoice data ", data);
    }
  }, [data]);
  const rateofgold = data.buydata.data[0].result.data.preTaxAmount;
  const Goldweight = data.buydata.data[0].result.data.quantity;
  const GST = data.buydata.data[0].result.data.taxes.totalTaxAmount;
  // console.log("GSYTT", GST);
  const Totalamount = data.buydata.data[0].result.data.totalAmount;
  const transectionID = data.buydata.data[0].result.data.transactionId;
  const UniqueId = data.buydata.data[0].result.data.uniqueId;
  const inoviceno = data.buydata.data[0].result.data.invoiceNumber;
  const invoicepath = data.buydata.data[0].invoicePath;

  const [updatedInvoicePath, setUpdatedInvoicePath] = useState("");

  useEffect(() => {
    if (invoicepath?.includes("https://app.batuk.in/")) {
      const newPath = invoicepath.replace("https://app.batuk.in/", "");
      setUpdatedInvoicePath(newPath);
    } else {
      setUpdatedInvoicePath(invoicepath || "");
    }
  }, [data]);
  return (
    <>
      {/* <div className="container" >

            <Link
                to="/dashboard/buysilver"
                className="btn btn-secondary goBack ms-5 my-4 mb-5"
                style={{ backgroundColor: "#4A2A7D" }}
            >
                <span>
                    Back
                </span>
            </Link>

            <div className="row d-flex justify-content-between">
                <div className="col-md-2"></div>
                <div className="col-md-6  " style={{ height: "auto", border: "2px solid #E3B041", borderRadius: "10px" }}>
                    <div className="row d-flex justify-content-around" >

                        <div className='col  d-flex m-2 p-1' style={{ border: "2px solid #E3B041", borderRadius: "10px", boxShadow: "0px 0px 10px rgba(128, 0, 128, 0.5)" }}>
                            <div className='col-md-2 p-2' >
                                <img src={Goldlogo} alt="here is logo" className='img-fluid' style={{ height: "80px" }} />
                            </div>
                            <div className='col-md-4   p-2 ml-5 ' style={{ height: "auto" }}>
                                <h2 className='text-center fw-bold' style={{ color: "#4A2A7D" }}>Augmont</h2>
                                <p className='text-center' style={{ color: "#4A2A7D" }}>SilverWeight: {Goldweight} gm</p>
                            </div>
                        </div>
                    </div>


                    <div className='row'>
                        <h4 className='m-2 fw-bold ' style={{ color: "#4A2A7D" }}>Payment Details</h4>
                        <table class="table table-borderless" >

                            <tbody style={{ color: "#4A2A7D" }}>
                                <tr >

                                    <td></td>

                                    <td>Rate of gold (&#8377;)</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="mr-5">{rateofgold}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Weight (g)</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{Goldweight}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>GST</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{GST}</td>
                                </tr>
                            
                                <tr>
                                    <td></td>
                                    <td className="fw-bold fs-5" style={{ color: "#880E4F" }}>Total Amount</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="fw-bold fs-5" style={{ color: "#880E4F" }}>{Totalamount}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>Transaction Id/Order Id</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{transectionID}</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>Unique Id</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{UniqueId}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Invoice Number</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{inoviceno}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className="fs-5 fw-bold" style={{ color: "#880E4F" }}>Status</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-success fs-5 fw-bold">Success</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td className=' text-center' >
                                        <a target="blank" href={`${process.env.REACT_APP_BASE_URL}/` + invoicepath} className='  fs-4 fw-bold' style={{ textDecoration: "none", color: "#4A2A7D" }}>
                                            <h6 className=''>
                                                <i class="fa-regular fa-copy fs-5"></i></h6>
                                            <span>Get Invoice</span></a>
                                    </td>
                                    <td >

                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div> */}

      <div style={{ width: "100%" }}>
        {/* <Link
        to="/dashboard/buygold"
        className="btn btn-secondary goBack ms-5 "
        style={{ backgroundColor: "#4A2A7D" }}
      >
        <span>
          Back
        </span>
      </Link> */}
        <div className="row d-flex justify-content-center">
          {/* <div className="col-md-1"></div> */}
          <div
            className="col-md-11  "
            style={{
              height: "auto",
              border: "2px solid #E3B041",
              borderRadius: "10px",
            }}
          >
            <div className="row d-flex justify-content-around">
              <div
                className="col  d-flex m-2 p-1"
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  border: "2px solid #E3B041",
                  borderRadius: "10px",
                  // boxShadow: "0px 0px 10px rgba(128, 0, 128, 0.5)",
                }}
              >
                <div className="col-md-2 ">
                  <img
                    src="/images/silver_shield.png"
                    alt="silver_shield_logo"
                    className="img-fluid"
                    style={{ height: "80px" }}
                  />
                </div>
                <div
                  className="col-md-4   p-2 ml-5 "
                  style={{ height: "auto" }}
                >
                  <h2
                    className="text-center fw-bold"
                    style={{ color: "#4A2A7D" }}
                  >
                    {augmont}
                  </h2>
                  <p className="text-center" style={{ color: "#4A2A7D" }}>
                    {silverWeight} {Goldweight} gm
                  </p>
                </div>
              </div>
            </div>

            <div
              style={{
                color: "#4A2A7D",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <h4 className="m-2 fw-bold ">{paymentDetails}</h4>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>{rateOfSilver}</div>
                <div>{rateofgold}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Weight (gm)</div>
                <div>{Goldweight}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>{gst}</div>
                <div>{GST}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5>Total Amount (₹)</h5>
                <h5>{Totalamount}</h5>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div> {transactionId} </div>
                <div>{transectionID}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div> {uniqueId} </div>
                <div>{UniqueId}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div> {invoiceNum}</div>
                <div>{inoviceno}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5> {status} </h5>
                <h5 style={{ color: "green" }}>{success}</h5>
              </div>
            </div>
            <div className=" text-center mb-3">
              <a
                target="blank"
                href={updatedInvoicePath}
                className="  fs-4 fw-bold"
                style={{
                  textDecoration: "none",
                  color: "#4A2A7D",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  gap: "5px",
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<TbFileInvoice />}
                  sx={{
                    backgroundColor: "#4a2a7d",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#4a2a7d",
                    },
                  }}
                >
                  Invoice
                </Button>
              </a>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </>
  );
}
export default SilverInvoice;
