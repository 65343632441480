import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
// import noHistoryFound from "../images/history-not-found.png";
import { goldFDHistoryApi } from "../redux/features/goldFD/goldFDHistorySlice";
import { historyApi } from "../redux/features/historySlice";
import "./history.css";

const HistoryPortfolio = () => {
  const [showGoldFD, setShowGoldFD] = useState(false);
  const sliceLoading = useSelector((state) => state?.historySlice?.status);
  const sliceResponse = useSelector((state) => state?.historySlice?.data);
  const { t } = useTranslation();
  const { allBtn, goldBtn, silverBtn } = t("historyPage.filterBtns");
  const {
    goldBought,
    goldRedeemed,
    silverRedeemed,
    silverBought,
    goldSold,
    silverSold,
  } = t("historyPage");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Token = sessionStorage.getItem("token");

  // State variables for filtering
  const [filter, setFilter] = useState("all"); // Default filter
  const [filteredUserList, setFilteredUserList] = useState([]);

  const [activeFDFilter, setActiveFDFilter] = useState("active");

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    const currentDate = new Date();
    const endDateFormatted = formatDate(currentDate);

    const startDateObj = new Date(currentDate);
    startDateObj.setMonth(currentDate.getMonth() - 3);
    const startDateFormatted = formatDate(startDateObj);

    const payload = {
      endDate: endDateFormatted,
      startDate: startDateFormatted,
    };

    dispatch(historyApi(payload));
  }, []);

  useEffect(() => {
    setFilteredUserList(sliceResponse?.data || []);
  }, [sliceResponse?.data]);

  // Filtered list effect
  useEffect(() => {
    if (filter === "all") {
      setFilteredUserList(sliceResponse?.data || []);
    } else {
      setFilteredUserList(
        (sliceResponse?.data || []).filter((item) => item.metalType === filter)
      );
    }
  }, [filter]);

  const handleFilterChange = (type) => {
    if (type == "all" || type == "gold" || type == "silver") {
      setShowGoldFD(false);
      setFilter(type);
    }

    if (type == "active") {
      setShowGoldFD(true);
      setFilter(type);
      setActiveFDFilter("active");
      dispatch(goldFDHistoryApi(type));
    }
  };

  const sendDataToInvoice = (item) => {
    console.log("DISHANT ID++++++++++++++++++++++", item);
    const stateData =
      item.type === "SPOT GOLD"
        ? {
            spotGoldId: item.transactionId,
            type: item.type,
            totalAmt: item.totalAmount,
          }
        : { item, type: item.type || "fd" };

    navigate("/dashboard/historyinvoice", {
      state: stateData,
    });
  };

  const getGoldFDHistory = (status) => {
    setActiveFDFilter(status); // Update active filter state for FD
    dispatch(goldFDHistoryApi(status));
  };

  const fdResponse = useSelector((state) => state.goldFDHistorySlice.data.data);
  console.log("DISHANT===================", fdResponse);
  // useEffect(() => {
  //   dispatch(goldFDHistoryApi("active"));
  //   dispatch(goldFDHistoryApi("close"));
  // }, []);

  return (
    <div style={{ width: "100%" }}>
      {/* =========================BUTTONS============================= */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div
          id="myBtnContainer"
          className="mb-20"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            <Button
              variant={filter == "all" ? "contained" : "outlined"}
              onClick={() => handleFilterChange("all")}
              sx={{
                backgroundColor: filter === "all" ? "#472878" : "transparent",
                color: filter === "all" ? "white" : "#472878",
                borderColor: filter === "all" ? "transparent" : "#472878",
                "&:hover": {
                  backgroundColor: "#472878",
                  color: "white",
                },
              }}
            >
              All
            </Button>

            <Button
              variant={filter == "gold" ? "contained" : "outlined"}
              onClick={() => handleFilterChange("gold")}
              sx={{
                backgroundColor: filter === "gold" ? "#472878" : "transparent",
                color: filter === "gold" ? "white" : "#472878",
                borderColor: filter === "gold" ? "transparent" : "#472878",
                "&:hover": {
                  backgroundColor: "#472878",
                  color: "white",
                },
              }}
            >
              gold
            </Button>

            <Button
              variant={filter == "silver" ? "contained" : "outlined"}
              onClick={() => handleFilterChange("silver")}
              sx={{
                backgroundColor:
                  filter === "silver" ? "#472878" : "transparent",
                color: filter === "silver" ? "white" : "#472878",
                borderColor: filter === "silver" ? "transparent" : "#472878",
                "&:hover": {
                  backgroundColor: "#472878",
                  color: "white",
                },
              }}
            >
              silver
            </Button>

            {/* <Button
              variant={filter == "active" ? "contained" : "outlined"}
              onClick={() => handleFilterChange("active")}
              sx={{
                backgroundColor:
                  filter === "active" ? "#472878" : "transparent",
                color: filter === "active" ? "white" : "#472878",
                borderColor: filter === "active" ? "transparent" : "#472878",
                "&:hover": {
                  backgroundColor: "#472878",
                  color: "white",
                },
              }}
            >
              Gold FD
            </Button> */}
          </div>

          {showGoldFD && (
            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              <Button
                variant={activeFDFilter == "active" ? "contained" : "outlined"}
                onClick={() => getGoldFDHistory("active")}
                sx={{
                  backgroundColor:
                    activeFDFilter === "active" ? "#472878" : "transparent",
                  color: activeFDFilter === "active" ? "white" : "#472878",
                  borderColor:
                    activeFDFilter === "active" ? "transparent" : "#472878",
                  "&:hover": {
                    backgroundColor: "#472878",
                    color: "white",
                  },
                }}
              >
                Active
              </Button>

              <Button
                variant={activeFDFilter == "close" ? "contained" : "outlined"}
                onClick={() => getGoldFDHistory("close")}
                sx={{
                  backgroundColor:
                    activeFDFilter === "close" ? "#472878" : "transparent",
                  color: activeFDFilter === "close" ? "white" : "#472878",
                  borderColor:
                    activeFDFilter === "close" ? "transparent" : "#472878",
                  "&:hover": {
                    backgroundColor: "#472878",
                    color: "white",
                  },
                }}
              >
                Close
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className="mx-auto">
        {!showGoldFD && (
          <>
            {sliceLoading == "loading" ? (
              <div style={{ height: "90vh" }}>
                <Loader />
              </div>
            ) : filteredUserList?.length > 0 ? (
              filteredUserList.map((item, index) => (
                <div
                  key={index}
                  onClick={() => sendDataToInvoice(item)}
                  className="card p-2 mb-4 history_card"
                >
                  <div>
                    {item.metalType === "gold" ? (
                      <img
                        src="/images/gold_shield.png"
                        alt="gold"
                        style={{ width: 60 }}
                      />
                    ) : (
                      <img
                        src="/images/silver_shield.png"
                        alt="silver"
                        style={{ width: 60 }}
                      />
                    )}
                  </div>
                  <div>
                    {item?.ProductName ? (
                      <div>
                        {item?.ProductName?.map((productName, index) => (
                          <div key={index} style={{ color: "#4a2a7d" }}>
                            {productName?.name || productName?.productName}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div style={{ color: "#4a2a7d" }}>{item.type}</div>
                    )}
                    {/* <p style={{ color: "#4A2A7D" }}>{item.quantity[0]}gm</p> */}
                  </div>
                  <div style={{ color: "#4A2A7D" }}>
                    ₹{item.totalAmount}
                    <p>
                      {new Date(item.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: item.status === "cancelled" ? "red" : "green",
                    }}
                  >
                    {item.status}
                  </div>
                </div>
              ))
            ) : (
              <div className="text-dark text-center">
                <img
                  src="/images/history-not-found.png"
                  className="noHistoryFound p-2"
                  alt="No History"
                />
                <h6>No History Found</h6>
                <p>Fill this Space with your Order</p>
              </div>
            )}
          </>
        )}
      </div>

      <div className="mx-auto">
        {showGoldFD && (
          <>
            {sliceLoading == "loading" ? (
              <Loader />
            ) : fdResponse?.length > 0 ? (
              fdResponse.map((item, index) => (
                <div key={index} className="text-decoration-none mb-4">
                  <div
                    onClick={() => sendDataToInvoice(item)}
                    className="text-decoration-none newItem"
                  >
                    <div
                      className="card p-2 mb-2"
                      style={{
                        boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)",
                      }}
                      // style={{border:"2px solid gold"}}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 1fr",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <div>
                          <div>{item.schemaName}</div>
                          <div>{item.jewellerUserAccountName}</div>
                        </div>
                        <div>
                          <div>Start Date: {item.startDate}</div>
                          <div>End Date: {item.endDate}</div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "18px",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            color: item.status === "close" ? "red" : "green",
                          }}
                        >
                          {item.status}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-dark text-center">
                <img
                  src="/images/history-not-found.png"
                  className="noHistoryFound p-2"
                  alt="No History"
                />
                <h6>No History Found</h6>
                <p>Fill this Space with your Order</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default HistoryPortfolio;
