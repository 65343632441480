import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdOutlineTimer } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../components/Footer.jsx";
import { useDispatch, useSelector } from "react-redux";
import { getGoldApi } from "../../redux/features/goldSlice.js";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { sipSchema } from "../../utils/validationSchemas.js";
import createSipSlice, {
  createSipApi,
  resetCreateSipState,
} from "../../redux/features/sip/createSipSlice.js";

import { FaCalendarAlt } from "react-icons/fa";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom/dist/index.js";
import Timer from "../../components/Timer.jsx";
import Swal from "sweetalert2";
import { IoMdClose } from "react-icons/io";
import { decryptFields } from "../../utils/kycStatus.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function CustomGoldAndSilverSip() {
  const dispatch = useDispatch();
  const location = useLocation();
  const metalType = location.pathname.includes("gold") ? "Gold" : "Silver";

  const [activeAmount, setActiveAmount] = useState(null);

  const livePriceApiResponse = useSelector(
    (state) => state.goldSlice.data.data
  );

  const { gBuy, sBuy } = livePriceApiResponse?.[0] || {};

  useEffect(() => {
    dispatch(getGoldApi());
  }, [dispatch]);

  const { values, handleSubmit, handleChange, errors, touched, setFieldValue } =
    useFormik({
      initialValues: {
        date: "",
        amount: "",
      },
      validationSchema: sipSchema,
      onSubmit: (values) => {
        handleOpen2();

        // const payload = {
        //   day_of_month: values.date || "",
        //   amount: values.amount || "",
        //   frequency: "monthly",
        //   metal_type: metalType.toLowerCase() || "gold",
        // };

        // Swal.fire({
        //   title: "Confirm SIP",
        //   html: `SIP Amount: ₹${values.amount}<br> Frequency: Monthly <br> Day of Month: ${values.date} st of every month`,
        //   icon: "info",
        //   showCancelButton: true,
        //   confirmButtonText: "Proceed",
        //   cancelButtonText: "Cancel",
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     // Dispatch action if confirmed
        //     dispatch(createSipApi(payload));
        //   }
        // });
      },
    });

  const handleCreateSip = () => {
    const payload = {
      day_of_month: values.date || "",
      amount: values.amount || "",
      frequency: "monthly",
      metal_type: metalType.toLowerCase() || "gold",
    };
    dispatch(createSipApi(payload));
    handleClose2();
  };

  const handleAmountChange = (event) => {
    const value = event.target.value;

    // Regular expression to allow only digits and an optional decimal point
    const regex = /^\d*\.?\d*$/;

    if (regex.test(value) || value === "") {
      setFieldValue("amount", value);

      if (!isNaN(value) && value !== "") {
        let multipliedValue = 0;

        if (metalType === "Gold") {
          multipliedValue = Number(value) / gBuy;
        } else if (metalType === "Silver") {
          multipliedValue = Number(value) / sBuy;
        }

        setFieldValue("result", multipliedValue);
      } else {
        setFieldValue("result", 0);
      }
    }
  };

  // Add this useEffect to handle changes in metalType
  useEffect(() => {
    setFieldValue("result", 0); // Reset result when metalType changes
  }, [metalType, setFieldValue]);

  //   =================================================
  const formatDay = (day) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = day % 100;
    return `${day}${
      suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]
    } day of every month`;
  };

  // Handle day selection and set only the day number in Formik
  const handleDaySelect = (day) => {
    setSelectedDay(day);
    setFieldValue("date", day); // Store only the day number
    handleClose();
  };

  // Modal open/close state
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const createSipLoading = useSelector((state) => state.createSipSlice.status);

  const createSipResponse = useSelector(
    (state) => state.createSipSlice.data.data
  );

  useEffect(() => {
    if (createSipResponse?.data?.authLink) {
      window.location.href = createSipResponse?.data.authLink;
    }
    dispatch(resetCreateSipState());
  }, [createSipResponse?.data?.authLink]);

  const [selectedDay, setSelectedDay] = useState(null);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  // ===========================KYC STATUS====================

  const [userDetails, setUserDetails] = useState({
    pan: false,
    bank: false,
    address: false,
  });

  useEffect(() => {
    // Get the stored userDetails from localStorage
    const storedDetails = JSON.parse(localStorage.getItem("userDetails")) || {};

    // Decrypt the userDetails fields
    const decryptedDetails = decryptFields(storedDetails, [
      "pan",
      "bank",
      "address",
    ]);

    // Set the decrypted values in the state
    setUserDetails({
      pan: decryptedDetails.pan || false,
      bank: decryptedDetails.bank || false,
      address: decryptedDetails.address || false,
    });
  }, []);

  console.log("user detail===============", userDetails);

  return (
    <div>
      <div>
        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ textAlign: "center", color: "#4a2a7d" }}
              >
                SIP Amount
              </Typography>
              <IoMdClose
                size={25}
                color="#4a2a7d"
                onClick={handleClose2}
                style={{ cursor: "pointer" }}
              />
            </Box>

            <div
              style={{
                backgroundColor: "#DCDAFF",
                display: "flex",
                justifyContent: "space-between",
                padding: "1rem",
                borderRadius: "0.5rem",
                margin: "1rem auto",
                color: "#4a2a7d",
              }}
            >
              <div style={{ fontWeight: "bold" }}>
                <div>SIP Amount</div>
                <div>Frequency</div>
                <div>Day Of Month</div>
              </div>
              <div>
                <div>:</div>
                <div>:</div>
                <div>:</div>
              </div>
              <div style={{ textAlign: "right" }}>
                <div>₹{values.amount}</div>
                <div>Monthly</div>
                <div>{values.date} date of every month</div>
              </div>
            </div>
            <div style={{ color: "#4a2a7d" }}>
              <b>Note: </b>First debit will happen today. Auto debit begins next
              month.
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <Button
                onClick={handleClose2}
                variant="outlined"
                sx={{
                  color: "#4a2a7d",
                  borderColor: "#4a2a7d",
                  "&:hover": {
                    color: "#4a2a7d",
                    borderColor: "#4a2a7d",
                  },
                }}
              >
                cancel
              </Button>

              <Button
                variant="contained"
                onClick={handleCreateSip}
                sx={{
                  backgroundColor: "#4a2a7d",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#4a2a7d",
                    color: "white",
                  },
                }}
              >
                Pay
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
      {createSipLoading === "loading" && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <div className="inner-container">
        <div className="inner-div">
          <button className="btn-ir card-btn">{metalType}</button>
          <h5 style={{ color: "#472878" }}>{metalType} Live Price</h5>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p className="medium-text">
              {metalType === "Gold" ? (
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    color: "#FFDF00",
                  }}
                >
                  ₹{gBuy}/gm
                </span>
              ) : metalType === "Silver" ? (
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    color: "#A6A6A6",
                  }}
                >
                  ₹{sBuy}/gm
                </span>
              ) : (
                <span>Invalid transaction type</span>
              )}
            </p>

            <p style={{ color: metalType === "Silver" ? "#A6A6A6" : "gold" }}>
              +3% GST
            </p>
          </div>
          <p className="" style={{ color: "#472878" }}>
            {metalType === "Gold" ? "24K 99.9% Pure Gold" : "99.9% Pure Silver"}
          </p>
        </div>
        <div className="inner-div hide-text">Div 2</div>
        <div className="inner-div">
          <img
            src={
              metalType === "Gold" ? "/images/gold_shield.png" : "/images/silver_shield.png"
            }
            alt={`${metalType} image`}
            style={{ width: "35%", marginBottom: "1rem" }}
            className="coin-img"
          />
          <div
            className="timer"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "5px",
              color: "#472878",
            }}
          >
            <MdOutlineTimer />
            <div>{metalType} rate expires in: </div>
            <Timer />
            {/* <div className="">{formatTime(timer)}</div> */}
          </div>
        </div>
      </div>
      {/* ************************ */}
      <form
        onSubmit={handleSubmit}
        style={{ maxWidth: "1240px", margin: "auto" }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          {/* ===============DATE================= */}
          <FormControl
            fullWidth
            variant="outlined"
            error={touched.date && Boolean(errors.date)}
          >
            <InputLabel htmlFor="outlined-adornment-day">
              Select Day For Payments
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-day"
              value={values.date ? formatDay(values.date) : ""} // Format day for display
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle day selection"
                    edge="end"
                    onClick={handleOpen}
                  >
                    <FaCalendarAlt />
                  </IconButton>
                </InputAdornment>
              }
              label="Select Day For Payments"
              readOnly
              onClick={handleOpen}
            />
            <FormHelperText>
              {touched.date && errors.date ? errors.date : ""}
            </FormHelperText>
          </FormControl>

          {/* +++++++++++++++MODAL++++++++++++++++++ */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ color: "#4a2a7d", marginBottom: "1rem" }}
                >
                  Select Day of SIP
                </Typography>
                <IoMdClose
                  size={25}
                  color="#4a2a7d"
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  {Array.from({ length: 28 }, (_, index) => (
                    <Grid item xs={3} key={index}>
                      <Button
                        variant="outlined"
                        onClick={() => handleDaySelect(index + 1)}
                        fullWidth
                        sx={{
                          borderColor:
                            selectedDay === index + 1 ? "#4a2a7d" : "#4a2a7d", // Active border color
                          color: selectedDay === index + 1 ? "#fff" : "#4a2a7d", // Active text color
                          backgroundColor:
                            selectedDay === index + 1
                              ? "#4a2a7d"
                              : "transparent", // Active background color
                          "&:hover": {
                            borderColor: "#4a2a7d",
                            backgroundColor:
                              selectedDay === index + 1
                                ? "#4a2a7d"
                                : "rgba(74, 42, 125, 0.1)",
                          },
                        }}
                      >
                        {index + 1}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Modal>

          {/* ===============AMOUNT================= */}

          <FormControl fullWidth>
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              name="amount"
              startAdornment={
                <InputAdornment position="start">₹</InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  {(values.result || 0).toFixed(4)} gm
                </InputAdornment>
              }
              label="Amount"
              value={values.amount}
              onChange={handleAmountChange}
              error={touched.amount && Boolean(errors.amount)}
            />
            <FormHelperText
              style={{
                color: touched.amount && errors.amount ? "red" : "inherit", // Change to red if error
              }}
            >
              {touched.amount && errors.amount ? errors.amount : "Minimum ₹100"}
            </FormHelperText>
          </FormControl>
        </div>

        <div
          style={{
            fontWeight: "bold",
            textAlign: "center",
            marginTop: "2rem",
            color: "#4a2a7d",
          }}
        >
          Recommended:
        </div>

        <div className="button-container" style={{ gap: "0.5rem" }}>
          {["100", "200", "300", "400", "500"].map((amount) => (
            <button
              type="button"
              className={`buttonBase ${
                activeAmount === amount ? "buttonActive" : ""
              }`}
              style={{ fontWeight: "bold" }}
              key={amount}
              onClick={() => {
                setActiveAmount(amount);
                setFieldValue("amount", amount);

                // Update the result based on the selected amount
                let multipliedValue = 0;

                if (metalType === "Gold") {
                  multipliedValue = Number(amount) / gBuy;
                } else if (metalType === "Silver") {
                  multipliedValue = Number(amount) / sBuy;
                }

                setFieldValue("result", multipliedValue);
              }}
            >
              ₹{amount}
            </button>
          ))}
        </div>

        <div className="bottom-button-container">
          {userDetails.pan && userDetails.bank && userDetails.address ? (
            <button
              type="submit"
              className="btn-ir buy-btn"
              style={{
                borderRadius: "50px",
                fontWeight: "bold",
                width: "100%",
              }}
            >
              Proceed
            </button>
          ) : (
            <Link to="/dashboard/ProfileSetting">
              <button
                type="submit"
                className="btn-ir buy-btn"
                style={{
                  borderRadius: "50px",
                  fontWeight: "bold",
                  width: "100%",
                }}
              >
                Complete KYC
              </button>
            </Link>
          )}
        </div>
      </form>
      <Footer />
    </div>
  );
}
