import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import { FaUserCircle } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Language from "./Language";
import AboutBatuk from "./AboutBatuk";
import FAQ from "./FAQ";
import DeleteAccount from "./DeleteAccount";
import { useTranslation } from "react-i18next";
import { PiWechatLogoDuotone } from "react-icons/pi";

const Profile = () => {
  const { t } = useTranslation();
  const { languageTab, aboutBatukTab, helpSupportTab } = t("profileTabs");
  const navigate = useNavigate();
  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  const [value, setValue] = useState("1");
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    mobile_number: "",
  });

  const Token = sessionStorage.getItem("token");

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = async () => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const getData = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getProfile`,
        {
          headers,
        }
      );
      const responseData = getData.data.data;
      if (responseData && responseData.length > 0) {
        const item = responseData[0]; // Assuming the response is an array with one item
        setProfile({
          name: item.fullName,
          email: item.emailAddress,
          mobile_number: item.mobileNumber,
        });
      }
    } catch (error) {
      // console.error("Error fetching profile data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <>
      <section id="Profile">
        <div className="row mt-5 mb-4" style={{ color: "" }}>
          <div className="col-sm-12">
            <div className="card p-2 profile_card mb-5">
              <Link to="/dashboard/ProfileSetting">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    gap: "10px",
                  }}
                >
                  <div style={{ width: "5.5rem" }}>
                    <img src="/images/profileHome.png" width="100%" alt="profile" />
                  </div>
                  <div
                    style={{
                      color: "#4A2A7D",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <h4 className="ProfileTxt">{profile.name}</h4>
                    <div className="ProfileTxtsm">{profile.email}</div>
                    <div className="ProfileTxtsm">{profile.mobile_number}</div>
                  </div>
                  <div className="col-sm-3 d-flex justify-content-center align-items-center">
                    <div className="editDiv">
                      <FiEdit2
                        className="editIcon"
                        style={{ color: "#472878" }}
                      />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <Link to="/dashboard/sip-history">
              <div
                style={{
                  // boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  padding: "0.5rem",
                  marginBottom: "2rem",
                  display: "flex",
                  gap: "2rem",
                  border: "1px solid lightgray",
                  borderRadius: "0.5rem",
                  alignItems: "center",
                }}
              >
                <div>
                  <img src="/images/gold_shield.png" alt="gold_shield" width={100} />
                </div>
                <Link to="/dashboard/sip-history">
                  <div style={{ fontSize: "1.5rem", color: "#4a2a7d" }}>
                    My SIP
                  </div>
                </Link>
              </div>
            </Link>

            <Link to="/dashboard/helpSupport">
              <div
                style={{
                  // boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  padding: "0.5rem",
                  marginBottom: "2rem",
                  display: "flex",
                  gap: "2rem",
                  border: "1px solid lightgray",
                  borderRadius: "0.5rem",
                  alignItems: "center",
                }}
              >
                <div>
                  <PiWechatLogoDuotone color="#4a2a7d" size={80} />
                </div>
                <Link to="/dashboard/helpSupport">
                  <div style={{ fontSize: "1.5rem", color: "#4a2a7d" }}>
                    Chat With Us
                  </div>
                </Link>
              </div>
            </Link>
            {/* ===========================TABS========================== */}
            <div className="card profile_card p-2">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#4A2A7D", // Active tab indicator (underline) color
                        },
                      }}
                    >
                      <Tab
                        label={languageTab}
                        value="1"
                        sx={{
                          color: "#472878",
                          borderBottom: "2px solid transparent", // Default border
                          "&.Mui-selected": {
                            color: "#4A2A7D", // Active tab color
                            borderBottom: "2px solid #4A2A7D", // Active tab border color
                            fontWeight: "bold", // Bold text for active tab
                          },
                        }}
                      />
                      <Tab
                        label={aboutBatukTab}
                        value="2"
                        sx={{
                          color: "#472878",
                          borderBottom: "2px solid transparent", // Default border
                          "&.Mui-selected": {
                            color: "#4A2A7D", // Active tab color
                            borderBottom: "2px solid #4A2A7D", // Active tab border color
                            fontWeight: "bold", // Bold text for active tab
                          },
                        }}
                      />
                      <Tab
                        label={helpSupportTab}
                        value="3"
                        sx={{
                          color: "#472878",
                          borderBottom: "2px solid transparent", // Default border
                          "&.Mui-selected": {
                            color: "#4A2A7D", // Active tab color
                            borderBottom: "2px solid #4A2A7D", // Active tab border color
                            fontWeight: "bold", // Bold text for active tab
                          },
                        }}
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Language />
                  </TabPanel>
                  <TabPanel value="2">
                    <AboutBatuk />
                  </TabPanel>
                  <TabPanel value="3">
                    <FAQ />
                  </TabPanel>
                  <TabPanel value="4">
                    <DeleteAccount />
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
