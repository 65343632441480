import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "../.././src/pages/Goldpage/goldify.css";
import { historyInvoiceApi } from "../redux/features/history/historyInvoiceSlice";
import { spotGoldInvoiceApi } from "../redux/features/history/spotGoldInvoiceSlice";

import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { TbFileInvoice } from "react-icons/tb";
import Loader from "../components/Loader";
import { goldFDCloseConfirmApi } from "../redux/features/goldFD/goldFDCloseConfirmSlice";
import { goldFDCloseApi } from "../redux/features/goldFD/goldFDCloseSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};

const HistoryInvoice = () => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const location = useLocation();
  // console.log("DISHANT LOCATION+++++++++++++++++", location.state);
  const dispatch = useDispatch();
  const historyData = useSelector(
    (state) => state?.historyInvoiceSlice?.data.data
  );
  // console.log("dishant++++++++++++++++++++++++", historyData);

  const [response, setResponse] = useState("");
  const Token = sessionStorage.getItem("token");

  const { t } = useTranslation();

  const {
    augmont,
    silverWeight,
    goldWeight,
    paymentDetails,
    weight,
    totalAmt,
    invoiceNum,
    success,
    getInvoice,
  } = t("historyInvoicePage");

  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const payload = {
      transactionId: location?.state?.item?.transactionId,
      type: location?.state?.item?.transaction_type,
      company: location?.state?.item?.company,
    };

    if (location?.state?.item?.transactionId) {
      dispatch(historyInvoiceApi(payload));
    }
  }, [location?.state?.item?.transactionId]);

  // ================================SPOT GOLD INVOICE API====================================

  const spotGoldSliceLoading = useSelector(
    (state) => state.spotGoldInvoiceSlice.status
  );
  const spotGoldSliceResponse = useSelector(
    (state) => state.spotGoldInvoiceSlice.data.data
  );

  const cancelChargeResponse = useSelector(
    (state) => state.cancelChargeSlice.data.data
  );
  console.log("SPOT GOLD SLICE ++++++++++++++++++++++++", spotGoldSliceLoading);

  const productIds = spotGoldSliceResponse?.productDetails?.map(
    (product) => product.id
  );

  // console.log(
  //   "CANCEL CHARGE SLICE ++++++++++++++++++++++++",
  //   cancelChargeResponse?.orderDetails?.orderdetails[0]?.cancelationChargePer
  // );
  console.log(
    "CANCEL CHARGE SLICE ++++++++++++++++++++++++",
    cancelChargeResponse?.totalCancellationCharges
  );
  useEffect(() => {
    if (location?.state?.spotGoldId) {
      dispatch(spotGoldInvoiceApi(location?.state?.spotGoldId));
    }
  }, [location?.state?.spotGoldId]);

  useEffect(() => {
    if (productIds) {
      // dispatch(cancelChargeApi(spotGoldSliceResponse?.orderid));
      // dispatch(cancelChargeApi(productIds));
    }
  }, [spotGoldSliceResponse?.productDetails]);

  const orderCreationTime = new Date(spotGoldSliceResponse?.createdAt);
  const currentTime = new Date();
  const timeDifference = currentTime - orderCreationTime; // Difference in milliseconds
  const isWithin24Hours = timeDifference <= 24 * 60 * 60 * 1000;

  const {
    invoiceId,
    invoicePath,
    preTaxAmount,
    quantity,
    totalAmount,
    totalTaxAmount,
    transactionId: historyTransactionId,
    uniqueId: historyUniqueId,
  } = historyData?.[0] || {};

  const [updatedInvoicePath, setUpdatedInvoicePath] = useState("");

  useEffect(() => {
    const invoicePath = historyData?.[0]?.invoicePath || ""; // Get the invoice path or default to an empty string

    // Check if the invoice path includes "http"
    if (invoicePath.includes("http")) {
      setUpdatedInvoicePath(invoicePath);
    } else if (invoicePath) {
      const baseURL = process.env.REACT_APP_BASE_URL || "";

      const fullPath = `${baseURL}${invoicePath}`;
      setUpdatedInvoicePath(fullPath);
    }
  }, [historyData]);

  // useEffect(() => {
  //   if (historyData?.[0]?.invoicePath?.includes("https://app.batuk.in/")) {
  //     const newPath = historyData[0].invoicePath.replace(
  //       // "https://app.batuk.in/",
  //       process.env.REACT_APP_SITE_KEY || "",
  //       ""
  //     );
  //     setUpdatedInvoicePath(newPath);
  //   } else {
  //     setUpdatedInvoicePath(historyData?.[0]?.invoicePath || "");
  //   }
  // }, [historyData]);

  console.log("DISHANT INVOICE+++++++++++++++++", updatedInvoicePath);

  let goldLease,
    schemaName,
    userAccountName,
    startDate,
    endDate,
    tenure,
    remaingTenure,
    interestRate,
    tdsRate,
    uniqueId,
    transactionId,
    status;

  if (location.state.type === "fd") {
    ({
      goldLease,
      schemaName,
      userAccountName,
      startDate,
      endDate,
      uniqueId,
      transactionId,
      status,
    } = location.state.item);
    ({ tenure, remaingTenure, interestRate, tdsRate } =
      location.state.item.fdScheme || {});
  }

  const closeConfirmResponse = useSelector(
    (state) => state.goldFDCloseConfirmSlice.data.data
  );

  // console.log("DISHANT CLOSE CONFIRM+++++++++++++++",closeConfirmResponse)

  const handleCloseConfirmFD = () => {
    setOpen(true);
    dispatch(goldFDCloseConfirmApi(transactionId));
  };
  const handleCloseFD = () => {
    dispatch(goldFDCloseApi(transactionId));
    setOpen(false);
    navigate("/dashboard/history");
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        {spotGoldSliceLoading == "loading" ? (
          <div style={{ height: "90vh" }}>
            <Loader />
          </div>
        ) : (
          <div className="row d-flex justify-content-center">
            <div
              className="col-md-11  "
              style={{
                height: "auto",
                border: "2px solid #E3B041",
                borderRadius: "10px",
              }}
            >
              <div className="row d-flex justify-content-around">
                <div
                  className="col  d-flex m-2 p-1"
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    border: "2px solid #E3B041",
                    borderRadius: "10px",
                  }}
                >
                  <div className="col-md-2 ">
                    <img
                      src={
                        // historyData.metalType == "gold" ||
                        location.state.type == "SPOT GOLD" ||
                        location.state.type == "GOLD BOUGHT" ||
                        location.state.type == "GOLD SOLD"
                          ? "/images/gold_shield.png"
                          : "/images/silver_shield.png"
                      }
                      alt="here is logo"
                      className="img-fluid"
                      style={{ height: "80px" }}
                    />
                  </div>
                  <div
                    // className="col-md-4   p-2 ml-5 "
                    style={{ marginLeft: "30% " }}
                  >
                    <h2
                      className="text-center fw-bold"
                      style={{ color: "#4A2A7D" }}
                    >
                      {augmont}
                    </h2>
                    {/* <p className="text-center" style={{ color: "#4A2A7D" }}>
                    {location.state.type === "SPOT GOLD"
                      ? `Weight: ${spotGoldSliceResponse?.product?.weight}`
                      : historyData.metalType === "gold"
                      ? `Gold Weight: ${response?.quantity}`
                      : `Silver Weight: ${response?.quantity}`}
                    gm
                  </p> */}
                  </div>
                </div>
              </div>

              <div
                style={{
                  color: "#4A2A7D",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {location.state.type === "SPOT GOLD" ? (
                  spotGoldSliceResponse?.productDetails?.map((item, i) => (
                    <div key={i}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Product Name</div>
                        <div>{item.product.productName}</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Weight</div>
                        <div>{item.product.weight}gm</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Quantity</div>
                        <div>{item.quantity}</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Price</div>
                        <div>₹{item.orderdetails[0].finalOrderPrice}</div>
                      </div>
                      <hr />
                    </div>
                  ))
                ) : location.state.type === "fd" ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>Gold Lease</div>
                      <div>{goldLease}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>Schema Name</div>
                      <div>{schemaName}gm</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>User Account Name</div>
                      <div>{userAccountName}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>Start Date</div>
                      <div>{startDate}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>End Date</div>
                      <div>{endDate}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>Tenure</div>
                      <div>{tenure} days</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>Remaining Tenure</div>
                      <div>{remaingTenure} days</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>Interest Rate</div>
                      <div>{interestRate}%</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>TDS Rate</div>
                      <div>{tdsRate}</div>
                    </div>
                    <hr />
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Weight</div>
                        <div>{quantity}gm</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Price</div>
                        <div>₹{totalAmount}</div>
                      </div>
                    </div>
                    <hr />
                  </div>
                )}

                {location.state.type != "fd" && (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h5>{totalAmt}</h5>
                    <h5>
                      {" "}
                      ₹
                      {location.state.type == "SPOT GOLD"
                        ? location?.state?.totalAmt
                        : totalAmount}
                    </h5>
                  </div>
                )}

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>Transaction Id</div>
                  <div>
                    {location.state.type === "SPOT GOLD"
                      ? spotGoldSliceResponse?.transactionId
                      : location.state.type === "fd"
                      ? location.state.item?.transactionId
                      : historyTransactionId}
                  </div>
                </div>

                {historyUniqueId ? (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div> Unique Id</div>
                    <div>{historyUniqueId}</div>
                  </div>
                ) : location.state.type === "fd" ? (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div> Unique Id</div>
                    <div>{uniqueId}</div>
                  </div>
                ) : null}

                {response?.invoiceId && (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div> {invoiceNum}</div>
                    <div>{response?.invoiceId}</div>
                  </div>
                )}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5> Status </h5>
                  <h5 style={{ color: "green", textTransform: "uppercase" }}>
                    {location?.state?.item?.status
                      ? location?.state?.item?.status
                      : spotGoldSliceResponse?.status}
                  </h5>
                </div>
              </div>

              {location.state.type == "fd" &&
                location?.state?.item?.status == "active" && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px 0",
                    }}
                  >
                    <Button
                      onClick={handleCloseConfirmFD}
                      variant="contained"
                      sx={{
                        backgroundColor: "#472878",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#472878",
                        },
                      }}
                    >
                      FD Close
                    </Button>
                  </div>
                )}

              <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      FD CLOSURE SUMMARY
                    </Typography>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Gold Lease</div>
                        <div>{goldLease}</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Schema Name</div>
                        <div>{schemaName}gm</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>User Account Name</div>
                        <div>{userAccountName}</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Start Date</div>
                        <div>{startDate}</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>End Date</div>
                        <div>
                          {closeConfirmResponse?.endDate &&
                            closeConfirmResponse?.endDate}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Number of Days</div>
                        <div>
                          {closeConfirmResponse?.noOfDays &&
                            closeConfirmResponse?.noOfDays}{" "}
                          Days
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Tenure</div>
                        <div>{tenure} days</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Remaining Tenure</div>
                        <div>{remaingTenure} days</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Interest Till Date</div>
                        <div>
                          {closeConfirmResponse?.interest &&
                            closeConfirmResponse?.interest}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>TDS Rate</div>
                        <div>{tdsRate}</div>
                      </div>
                      <hr />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5> Status </h5>
                        <h5
                          style={{ color: "green", textTransform: "uppercase" }}
                        >
                          {location?.state?.item?.status
                            ? location?.state?.item?.status
                            : spotGoldSliceResponse?.status}
                        </h5>
                      </div>
                    </div>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        sx={{
                          backgroundColor: "#472878",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#472878",
                          },
                        }}
                      >
                        cancel
                      </Button>
                      <Button
                        onClick={handleCloseFD}
                        variant="contained"
                        sx={{
                          backgroundColor: "#472878",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#472878",
                          },
                        }}
                      >
                        close FD
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              </div>

              {location.state.type !== "SPOT GOLD" && updatedInvoicePath && (
                <div className="text-center mb-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer" // Added for security with target="_blank"
                    href={updatedInvoicePath || ""}
                    className="fs-4 fw-bold"
                    style={{
                      textDecoration: "none",
                      color: "#4A2A7D",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      gap: "5px",
                    }}
                  >
                    {/* <i
                      className="fa-regular fa-copy fs-5"
                      style={{ marginTop: "9px" }}
                    ></i> */}
                    {/* <span>{getInvoice}</span> */}
                    <Button
                      variant="contained"
                      startIcon={<TbFileInvoice />}
                      sx={{
                        backgroundColor: "#4a2a7d",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#4a2a7d",
                        },
                      }}
                    >
                      Invoice
                    </Button>
                  </a>
                </div>
              )}

              {/* location.state.type == "SPOT GOLD" &&
                spotGoldSliceResponse?.status !== "Cancelled" &&
                isWithin24Hours && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px",
                    }}
                  >
                    <SpotGoldCancel
                      // id={spotGoldSliceResponse?.orderid}
                      id={productIds || ""}
                      transId={
                        (spotGoldSliceResponse?.transactionId &&
                          spotGoldSliceResponse?.transactionId) ||
                        ""
                      }
                      cancelCharge={
                        cancelChargeResponse?.totalCancellationCharges
                      }
                      // cancelCharge={
                      //   cancelChargeResponse?.orderDetails?.orderdetails[0]
                      //     ?.cancelationChargePer
                      // }
                    />
                  </div>
                ) */}

              {/* } */}
            </div>
            <div className="col-md-2"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default HistoryInvoice;
