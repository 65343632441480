const sidebarLinks = [
  {
    name: "Home",
    link: "/",
    img: "/images/home.png",
    img2: "/images/home2.png",
  },
  {
    name: "Digital Gold",
    link: "/dashboard/buygold",
    img: "/images/gold_shield.png",
  },
  {
    name: "Digital Silver",
    link: "/dashboard/buysilver",
    img: "/images/silver_shield.png",
  },
  {
    name: "Jewellery",
    link: "/shop",
    img: "/images/jewellery_main.png",
  },
  {
    name: "Gold SIP",
    link: "/dashboard/gold-sip",
    img: "/images/gold_sip.png",
  },
  {
    name: "Silver SIP",
    link: "/dashboard/silver-sip",
    img: "/images/silver_sip.png",
  },
  {
    name: "History",
    link: "/dashboard/history",
    img: "/images/historyHome.png",
    img2: "/images/history2.png",
  },
  {
    name: "Portfolio",
    link: "/dashboard/portfolio",
    img: "/images/portfolioHome.png",
    img2: "/images/portfolio2.png",
  },
  {
    name: "Profile",
    link: "/dashboard/profile",
    img: "/images/profileHome.png",
    img2: "/images/profile2.png",
  },
];

export default sidebarLinks;
