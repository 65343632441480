import { Button } from "@mui/material";
import React from "react";
import { FaCartShopping } from "react-icons/fa6";
import { IoMdStar, IoMdStarOutline } from "react-icons/io";
import { Link } from "react-router-dom";

export default function CustomProductCard({ item, handleAddCart }) {
  //   console.log("DISHANT IN CUSTOM CARD", item);
  return (
    <div
      // style={{
      //   boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      //   borderRadius: "7px",
      //   display: "flex",
      //   justifyContent: "space-between",
      //   flexDirection: "column",
      //   padding: "0.5rem",
      // }}
      className="product_card"
    >
      <Link to={`/shop/${item?.id}`}>
        <div
          className="image-container"
          style={{
            width: "100%",
            overflow: "hidden",
          }}
        >
          <img
            src={item?.productImage != "0" ? item.productImage : "/images/noImage.png"}
            className="zoom-image"
            width="100%"
            height={170}
            alt={item?.productName}
          />
        </div>
      </Link>
      <div
        style={{
          //   padding: "10px",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link to={`/shop/${item?.id}`}>
          <h5 className="product_heading">
            {item?.productName?.length > 34
              ? `${item?.productName?.substring(0, 34)}...`
              : item?.productName}
          </h5>
        </Link>
        <div style={{ display: "flex", gap: "5px" }}>
          <div>
            <IoMdStar color="#EDBF00" />
            <IoMdStar color="#EDBF00" />
            <IoMdStar color="#EDBF00" />
            <IoMdStar color="#EDBF00" />
            <IoMdStarOutline color="#EDBF00" />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "0.4rem",
            alignItems: "baseline",
            margin: "0.5rem 0",
          }}
        >
          <div
            style={{
              fontSize: "1.2rem",
              fontWeight: "500",
              color: "#4a2a7d",
              textAlign: "center",
            }}
          >
            ₹{item?.productPrice[0]?.finalProductPrice}
          </div>
          {/* <s style={{ color: "gray" }}>
                      ₹{item?.productPrice[0]?.finalProductPrice}
                    </s>
                    <span style={{ color: "green", fontWeight: "500" }}>
                      (0% OFF)
                    </span> */}
        </div>
        <Button
          variant="contained"
          fullWidth
          onClick={() => handleAddCart(item)}
          endIcon={<FaCartShopping />}
          sx={{
            backgroundColor: "#4a2a7d",
            color: "white",
            "&:hover": {
              backgroundColor: "#4a2a7d",
            },
          }}
        >
          Add to Cart
        </Button>
      </div>
    </div>
  );
}
