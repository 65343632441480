import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "./portfolio.scss";

const Portfolio = () => {
  const { t } = useTranslation();
  const {
    portfolioTitle,
    goldTitle,
    silverTitle,
    currentValue,
    goldInLocker,
    silverInLocker,
  } = t("portfolioPage");
  const navigate = useNavigate();
  useEffect(() => {
    const login = sessionStorage.getItem("isLOGIN");
    if (!login) {
      navigate("/");
    }
  }, []);

  const Token = sessionStorage.getItem("token");

  const [cvalue, setcvalue] = useState("");
  const [invalue, setincvalue] = useState("");
  const [lossvalue, setlossvalue] = useState("");
  const [pervalue, setervalue] = useState("");
  const [qty, setQty] = useState();
  const [silverData, setsilverData] = useState({
    balQuantity: "",

    currentAmount: "",

    investedAmount: "",

    percent: "",

    profitORloss: "",
  });
  useEffect(() => {
    callPortfolioAPI();
  }, []);

  const callPortfolioAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/act/getport`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
      });

      const data = await response.json();
      console.log("data main response", data);
      const apigold = data.data[0].augmont.gold;
      const apisilver = data.data[0].augmont.silver;
      setcvalue(apigold.currentAmount);
      setincvalue(apigold.investedAmount);
      setervalue(apigold.percent);
      setlossvalue(apigold.profitORloss);
      setQty(apigold.balQuantity);
      console.log("apigold", apigold);
      console.log("apisilver", apisilver);
      silverData.balQuantity = apisilver.balQuantity;
      silverData.currentAmount = apisilver.currentAmount;
      silverData.investedAmount = apisilver.investedAmount;
      silverData.percent = apisilver.percent;
      silverData.profitORloss = apisilver.profitORloss;
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  return (
    <div className="Portfolio">
      <h4 style={{ color: "#4A2A7D",marginBottom:"1rem" }}>{portfolioTitle}</h4>
      <div className="portfolio_container">
        <div className="single-portfolio ">
          <div
            // className="card  p-3 outer_card"
            // style={{
            //   border: "2px solid #E3B041",
            //   boxShadow: "1.95px 1.95px 2.6px rgba(0, 0, 0, 0.15)",
            // }}
            className="portfolio_card"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <div
                style={{
                  width: "30%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src="/images/gold_shield.png" width={"100%"} alt="Gold" />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  width: "100%",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      color: "#4A2A7D",
                      fontSize: "1.4rem",
                      fontWeight: "bold",
                    }}
                  >
                    Digital Gold
                  </div>
                  <div
                    style={{
                      color: "#4A2A7D",
                      fontSize: "1.4rem",
                    }}
                  >
                    ₹{invalue}
                  </div>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ fontSize: "1.2rem", color: "#4A2A7D" }}>
                    <strong>{currentValue}</strong>
                    <div>₹{cvalue}</div>
                    <div
                      className={`${
                        parseFloat(lossvalue) >= 0 ? "positive" : "negative"
                      }`}
                      style={{
                        color: lossvalue > 0 ? "green" : "red",
                      }}
                    >
                      {parseFloat(lossvalue) > 0 ? (
                        <MdArrowUpward />
                      ) : (
                        <MdArrowDownward />
                      )}
                      {Math.abs(lossvalue)} ({pervalue}%)
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      fontSize: "1.2rem",
                      color: "#4A2A7D",
                    }}
                  >
                    <strong>{goldInLocker}</strong>
                    <div style={{ color: "#DA9E16", fontWeight: "bold" }}>
                      {qty} gms
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" single-portfolio ">
          <div
            className="portfolio_card"
            // style={{
            //   border: "2px solid #E3B041",
            //   boxShadow: "1.95px 1.95px 2.6px rgba(0, 0, 0, 0.15)",
            // }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <div
                style={{
                  width: "30%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src="/images/silver_shield.png" width={"100%"} alt="silver" />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  width: "100%",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      color: "#4A2A7D",
                      fontSize: "1.4rem",
                      fontWeight: "bold",
                    }}
                  >
                    Digital Silver
                  </div>
                  <div
                    style={{
                      color: "#4A2A7D",
                      fontSize: "1.4rem",
                    }}
                  >
                    ₹{silverData.investedAmount}
                  </div>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ fontSize: "1.2rem", color: "#4A2A7D" }}>
                    <strong>{currentValue}</strong>
                    <div>₹{silverData.currentAmount}</div>
                    <div
                      className={`${
                        parseFloat(pervalue) >= 0 ? "positive" : "negative"
                      }`}
                      style={{
                        color: silverData.profitORloss > 0 ? "green" : "red",
                      }}
                    >
                      {parseFloat(silverData.profitORloss) > 0 ? (
                        <MdArrowUpward />
                      ) : (
                        <MdArrowDownward />
                      )}
                      {-silverData.profitORloss} ({silverData.percent}%)
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      fontSize: "1.2rem",
                      color: "#4A2A7D",
                    }}
                  >
                    <strong>{silverInLocker}</strong>
                    <div style={{ color: "#DA9E16", fontWeight: "bold" }}>
                      {silverData.balQuantity} gms
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
