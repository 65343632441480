import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { goldFDPreOrderApi } from "../../redux/features/goldFD/goldFDPreOrderSlice";
import Loader from "../../components/Loader";
import { goldFDCreateApi } from "../../redux/features/goldFD/goldFDCreateSlice";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { tAndCApi } from "../../redux/features/goldFD/tAndCSlice";
import { portfolioApi } from "../../redux/features/portfolio/portfolioSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "70vh",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

export default function GoldFDDetails() {
  const [inputValue, setInputValue] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const tAndCSliceResponse = useSelector((state) => state.tAndCSlice.data.data);
  const { heading, paragraphs, terms } = tAndCSliceResponse || {};
  // console.log("DISHANT TERMS++++++++++++++++++++++", tAndCSliceResponse);

  const portfolioSliceResponse = useSelector(
    (state) => state.portfolioSlice.data
  );

  console.log(
    "DISHANT PORTFOLIO++++++++++++++++++++++",
    portfolioSliceResponse
  );

  const preORderSliceLoading = useSelector(
    (state) => state.goldFDPreOrderSlice.status
  );
  const preORderSliceResponse = useSelector(
    (state) => state.goldFDPreOrderSlice
  );
  // console.log("DISHANT PRE ORDER++++++++++++++++++++++", preORderSliceResponse);

  const createSliceLoading = useSelector((state) => state.goldFDCreateSlice);
  const createSliceResponse = useSelector(
    (state) => state.goldFDCreateSlice.data
  );
  // console.log("DISHANT PRE ORDER++++++++++++++++++++++",createSliceResponse)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    name,
    jewellerUserAccountName,
    tenure,
    remaingTenure,
    interestRate,
    tdsRate,
    minQtyLease,
    startDate,
    endDate,
    profile,
    id,
  } = location.state || {};

  //   console.log("LOCATION============", location.state);
  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      fdSchemeId: id,
      quantity: inputValue,
    };

    console.log("PAYLOAD=============", payload);

    dispatch(goldFDCreateApi(payload));
    navigate("/dashboard/history");
  };

  useEffect(() => {
    const payload = {
      fdSchemeId: id,
      quantity: inputValue,
    };

    // console.log("PAYLOAD=============", payload);
    if (inputValue) {
      dispatch(goldFDPreOrderApi(payload));
    }
  }, [inputValue]);

  useEffect(() => {
    const payload = {
      fdSchemeId: id,
      uniqueId: "5ebabbef-7",
    };
    dispatch(tAndCApi(payload));
    dispatch(portfolioApi());
  }, []);

  const {
    endOfLease,
    interest,
    startDate: apiStartDate,
    endDate: apiEndDate,
  } = preORderSliceResponse?.data?.data?.result?.data || {};

  const [checkboxState, setCheckboxState] = useState(false);

  const handleCheckbox = () => {
    setCheckboxState(true);
  };

  return (
    <div style={{ color: "#472878" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div>
          <div style={{ fontWeight: "bold" }}>3.50% Interest p.a.</div>
          <div
            style={{
              backgroundColor: "#472878",
              padding: "5px 10px",
              borderRadius: "20px",
              color: "white",
              fontSize: "13px",
            }}
          >
            Maturity in 121 days
          </div>
        </div>
        <div style={{ width: "8rem" }}>
          <img src="/images/goldFDLogo.png" alt="logo" width="100%" />
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div
          style={{
            width: "60%",
            // marginBottom: "20px",
            margin: "auto",
            alignItems: "center",
            // display: "flex",
            // justifyContent: "center",
            // flexDirection: "column",
          }}
        >
          <TextField
            fullWidth
            label="Gold FD Amount"
            id="outlined-start-adornment"
            size="small"
            required
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Gm</InputAdornment>
              ),
            }}
            sx={{
              // width: "60%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "gold",
                },
                "&:hover fieldset": {
                  borderColor: "gold",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "gold",
                },
              },
            }}
          />
          <div style={{ marginTop: "5px", fontWeight: "bold" }}>
            Gold In Locker:{" "}
            {portfolioSliceResponse?.data?.[0]?.augmont?.gold?.balQuantity &&
              portfolioSliceResponse?.data?.[0]?.augmont?.gold?.balQuantity}
            gm
          </div>

          <div style={{ marginTop: "20px" }}>
            {preORderSliceLoading === "loading" ? (
              <Loader />
            ) : preORderSliceResponse?.data?.data?.result?.data ? (
              <div
                style={{
                  // width: "60%",
                  backgroundColor: "#DCDAFF",
                  padding: "10px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div>{endOfLease ? endOfLease : ""}gm on Maturity</div>
                  <div style={{ color: "green" }}>
                    +{interest}gm as Interest
                  </div>
                </div>
                <div>
                  <div>Start Date: {apiStartDate || ""}</div>
                  <div>End Date: {apiEndDate || ""}</div>
                </div>
              </div>
            ) : null}
            {/* (
              preORderSliceResponse?.errors[0]?.message?.errors?.userKyc[0]
                ?.message && (
                <div
                  style={{
                    color: "red",
                    fontStyle: "italic",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  *
                  {
                    preORderSliceResponse?.errors[0]?.message?.errors
                      ?.userKyc[0]?.message
                  }
                </div>
              )
            ) */}
          </div>
        </div>

        <div className="goldFD-container">
          <div className="goldFD-box1">
            <h3 style={{ marginBottom: "20px" }}>Scheme Details</h3>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>Scheme Name: </div>
                <div>{name}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>Jeweller Name: </div>
                <div>{jewellerUserAccountName}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>Tenure: </div>
                <div>{tenure} days</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>Remaining Tenure: </div>
                <div>{remaingTenure} days</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>Interest Rate: </div>
                <div>{interestRate}%</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>TDS Rate: </div>
                <div>{tdsRate}%</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>Minimum Quantity: </div>
                <div>{minQtyLease} gm</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>Start Date: </div>
                <div>{startDate}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>End Date: </div>
                <div>{endDate}</div>
              </div>
            </div>
          </div>
          <div className="goldFD-box1">
            <h3 style={{ marginBottom: "20px" }}>About Jeweller</h3>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>Name: </div>
                <div>{name}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>Since: </div>
                <div>{profile?.yearOfOperation} years</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>Turn Over: </div>
                <div>{profile?.turnOver} Cr.</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>State: </div>
                <div>{profile?.userState}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>City: </div>
                <div>{profile?.userCity}</div>
              </div>
              <h3>Description</h3>
              <div>{profile?.description}</div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <FormControlLabel
            onClick={handleOpen}
            required
            control={<Checkbox checked={checkboxState} />}
            label={
              <>
                I agree to the{" "}
                <b sx={{ color: "#472878" }} onClick={handleOpen}>
                  Terms & Conditions
                </b>
              </>
            }
          />
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {heading && heading}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {paragraphs && paragraphs}
            </Typography>
            <Typography sx={{ mt: 2 }}>{terms && terms}</Typography>
            <FormControlLabel
              onClick={() => {
                setCheckboxState(true);
                setOpen(false);
              }}
              required
              control={<Checkbox checked={checkboxState} />}
              label={
                <>
                  I agree to the{" "}
                  <b
                    sx={{ color: "#472878" }}
                    // onClick={() => setCheckboxState(true)}
                  >
                    Terms & Conditions
                  </b>
                </>
              }
            />
          </Box>
        </Modal>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#472878",
              color: "white",
              "&:hover": {
                backgroundColor: "#472878",
              },
            }}
          >
            Book Gold FD
          </Button>
        </div>
      </form>
    </div>
  );
}
