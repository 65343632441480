import {
  Button,
  FormHelperText,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import React, { useState } from "react";
import "./ContactUs.css";

import { FiPhone } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import Footer from "../../components/footer/Footer";
import { IoMdSend } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { contactUsApi } from "../../redux/features/contactUs/contactUsSlice";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { useFormik } from "formik";
import { contactUsSchema } from "../../utils/validationSchemas";

export default function ContactUs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const contactUsResponse = useSelector(
    (state) => state.contactUsSlice.data.data
  );

  // console.log(
  //   "Dishant CONTACT US API =====================",
  //   contactUsResponse
  // );

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFomFields((prevFields) => ({
  //     ...prevFields,
  //     [name]: value,
  //   }));
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const payload = {
  //     name: formFields.name,
  //     email: formFields.email,
  //     mobileNo: formFields.mobileNo,
  //     subject: formFields.subject,
  //     message: formFields.message,
  //   };

  //   await dispatch(contactUsApi(payload));

  //   setFomFields({
  //     name: "",
  //     email: "",
  //     mobileNo: "",
  //     subject: "",
  //     message: "",
  //   });

  //   navigate("/");
  // };

  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobileNo: "",
      subject: "",
      message: "",
    },
    validationSchema: contactUsSchema,
    onSubmit: async (values) => {
      console.log("input values++++++++++++=", values);

      const payload = {
        name: values.name,
        email: values.email,
        mobileNo: values.mobileNo,
        subject: values.subject,
        message: values.message,
      };

      await dispatch(contactUsApi(payload));

      // setFomFields({
      //   name: "",
      //   email: "",
      //   mobileNo: "",
      //   subject: "",
      //   message: "",
      // });

      // if (contactUsResponse?.[0]._id) {
      //   console.log("INSIDE ONSUBMIT=========", contactUsResponse);
      navigate("/");
      // }
    },
  });

  return (
    <div>
      <Breadcrumb currentPageName="Contact Us" />
      {/* =================BANNER================ */}
      <div className="contact-banner1">
        <img src="/images/contactBanner.png" alt="bannerImg" width="100%" />
        <div className="banner_innerbox">
          <h1
            style={{
              color: "white",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            }}
          >
            We're here for you!
          </h1>
          <h3
            style={{
              fontWeight: "500",
              color: "white",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            }}
          >
            If you need assistance or have any questions, please reach out to us
            using the form below. Your feedback matters to us.
          </h3>
        </div>
      </div>
      {/* ============================================= */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "2rem auto",
        }}
      >
        <h2 style={{ color: "#4A2A7D" }}>Contact Us</h2>
      </div>

      <div className="contact-main-box universal_container">
        {/* ======================ADDRESS========================  */}
        <div className="contact-inner-box1">
          <div className="contact-card">
            <div>
              <IoLocationOutline size={30} style={{ color: "#4A2A7D" }} />
            </div>
            <div>
              <h4 style={{ color: "#4A2A7D" }}>Address</h4>
              <Link
                to="https://maps.app.goo.gl/JVXccMHhAtXH73P99"
                target="_blank"
              >
                <p style={{ color: "#4A2A7D" }}>
                  <strong>Head Office:</strong> Office No 2, 3rd floor, A-1,
                  Sector 9, Noida, Uttar Pradesh- 201301
                </p>
              </Link>
              <hr />
              <Link
                to="https://maps.app.goo.gl/fQmP5XrdTxZBMdyB7"
                target="_blank"
              >
                <p style={{ color: "#4A2A7D" }}>
                  <strong>Branch Office:</strong> 10/B Yunus Building, S.S
                  Gaikwad Marg Near Metro Cinema, Mumbai, 400002
                </p>
              </Link>
            </div>
          </div>
          <div className="contact-card">
            <div>
              <FiPhone size={30} style={{ color: "#4A2A7D" }} />
            </div>
            <div>
              <h4 style={{ color: "#4A2A7D" }}>Call Us</h4>
              <a href="tel:+918448695359" target="_blank">
                <p style={{ color: "#4A2A7D" }}>+91 8448695359</p>
              </a>
            </div>
          </div>
          <div className="contact-card">
            <div>
              <MdOutlineEmail size={30} style={{ color: "#4A2A7D" }} />
            </div>
            <div>
              <h4 style={{ color: "#4A2A7D" }}>Email Us</h4>
              <a href="mailto:connect@batuk.in" target="_blank">
                <p style={{ color: "#4A2A7D" }}>connect@batuk.in</p>
              </a>
            </div>
          </div>
        </div>
        {/* ====================FORM=================== */}
        <div className="contact-inner-box2">
          {/* <h2 style={{ color: "#4A2A7D" }}>Contact Us</h2> */}
          <form className="contact-form-box" onSubmit={handleSubmit}>
            <TextField
              id="name"
              label="Full Name"
              name="name"
              variant="outlined"
              size="small"
              value={values.name}
              onChange={handleChange}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              id="email"
              label="Email"
              name="email"
              variant="outlined"
              size="small"
              value={values.email}
              onChange={handleChange}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              id="mobileNo"
              label="Mobile Number"
              name="mobileNo"
              variant="outlined"
              size="small"
              value={values.mobileNo}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (
                  e.target.value === "" ||
                  (re.test(e.target.value) && e.target.value.length <= 10)
                ) {
                  handleChange(e);
                }
              }}
              inputProps={{ maxLength: 10 }}
              error={touched.mobileNo && Boolean(errors.mobileNo)}
              helperText={touched.mobileNo && errors.mobileNo}
            />
            <TextField
              id="subject"
              label="Subject"
              name="subject"
              variant="outlined"
              size="small"
              value={values.subject}
              onChange={handleChange}
              error={touched.subject && Boolean(errors.subject)}
              helperText={touched.subject && errors.subject}
            />
            <div>
              <div
                className={`textarea-wrapper ${
                  touched.message && errors.message ? "error" : ""
                }`}
              >
                <TextareaAutosize
                  id="message"
                  minRows={3}
                  maxRows={3}
                  aria-label="Your query..."
                  placeholder="Your query..."
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  className="custom-textarea"
                />
              </div>
              {touched.message && errors.message && (
                <FormHelperText
                  error
                  style={{ marginLeft: "12px", fontSize: "0.75rem" }}
                >
                  {errors.message}
                </FormHelperText>
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                endIcon={<IoMdSend color="white" />}
                variant="contained"
                sx={{
                  backgroundColor: "#472878",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#472878",
                  },
                }}
              >
                Send
              </Button>
            </div>
          </form>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
